import { Inject, Injectable } from '@angular/core';
import { ITK_NG_DJANGO_API_CLIENT } from '@itk/ng-django-api';
import {
  AddAgentRequest,
  DeleteAgentRequest,
  DeleteAgentResponse,
  ErrorResponse,
  ItkDjangoApi,
  Member,
  PublicInfoResponse,
  UpdateDefaultStateRequest,
  UpdateDefaultStateResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  UpdateTelesalesRequest,
  UpdateTellesalesResponse,
  UpdateUserInfoResponse,
  User,
} from '@itk/universal-django-api';
import {
  catchError,
  debounceTime,
  filter,
  firstValueFrom,
  map,
  merge,
  of,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ItkMemberService {
  constructor(
    @Inject(ITK_NG_DJANGO_API_CLIENT)
    private readonly api: ItkDjangoApi,
  ) {}

  public readonly getMemberSource = new Subject<void>();
  public readonly getMember$ = this.getMemberSource.pipe(
    switchMap(() => this.api.member.getMember()),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public readonly memberRequest$ = this.api.member
    .getMember()
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  public readonly member$ = merge(this.getMember$, this.memberRequest$).pipe(
    debounceTime(10),
    map((x) => {
      if ('error' in x) {
        return {} as Member;
      }

      return x;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public readonly memberId$ = this.member$.pipe(
    map((x) => x.id),
    filter((x): x is number => typeof x === 'number'),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public readonly isAgency$ = this.member$.pipe(
    switchMap(() => this.api.payments.isAgency()),
    catchError(() => of(false)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public readonly memberAgents$ = this.member$.pipe(
    switchMap(() => this.api.member.getMemberAgents()),
    map((x) => {
      if ('error' in x) {
        return [] as Member[];
      }

      return x;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  getPublicInfo(
    liteToken: string,
  ): Promise<PublicInfoResponse | ErrorResponse> {
    return firstValueFrom(this.api.member.getPublicInfo(liteToken));
  }

  updateMemberUserInfo(
    user: User,
  ): Promise<UpdateUserInfoResponse | ErrorResponse> {
    return firstValueFrom(
      this.memberId$.pipe(
        switchMap((x) => this.api.member.updateMemberUserInfo(x, user)),
      ),
    ).then((x) => {
      this.getMemberSource.next();
      return x;
    });
  }

  updatePassword(
    password: UpdatePasswordRequest,
  ): Promise<UpdatePasswordResponse | ErrorResponse> {
    return firstValueFrom(
      this.memberId$.pipe(
        switchMap((x) => this.api.member.updateMemberUserPassword(x, password)),
      ),
    ).then((x) => {
      this.getMemberSource.next();
      return x;
    });
  }

  updateUserIsTelesales(
    is_telesales: UpdateTelesalesRequest,
  ): Promise<UpdateTellesalesResponse | ErrorResponse> {
    return firstValueFrom(
      this.memberId$.pipe(
        switchMap((m) =>
          this.api.member.updateMemberIsTellesales(m, is_telesales),
        ),
      ),
    ).then((x) => {
      this.getMemberSource.next();
      return x;
    });
  }

  updateDefaultState(
    default_state: UpdateDefaultStateRequest,
  ): Promise<UpdateDefaultStateResponse | ErrorResponse> {
    return firstValueFrom(
      this.memberId$.pipe(
        switchMap((m) => this.api.member.updateDefaultState(m, default_state)),
      ),
    ).then((x) => {
      this.getMemberSource.next();
      return x;
    });
  }

  addAgent(agent: AddAgentRequest): Promise<Member | ErrorResponse> {
    return firstValueFrom(this.api.member.addAgent(agent)).then((x) => {
      this.getMemberSource.next();
      return x;
    });
  }

  deleteAgent(
    agent: DeleteAgentRequest,
  ): Promise<DeleteAgentResponse | ErrorResponse> {
    return firstValueFrom(this.api.member.deleteAgent(agent)).then((x) => {
      this.getMemberSource.next();
      return x;
    });
  }
}
